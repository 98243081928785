/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Container from "react-bootstrap/Container"
import SSRProvider from "react-bootstrap/SSRProvider"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Seo from "./seo"

type Props = {
  children: React.ReactNode
  pageTitle?: string
}

const Layout = ({ children, pageTitle }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = pageTitle || data.site.siteMetadata?.title || `Title`

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Seo title={title} />
      <SSRProvider>
        <Container
          style={{
            marginTop: 30,
          }}
        >
          <h1>{title}</h1>
          {children}
        </Container>
      </SSRProvider>
      <footer
        style={{
          marginTop: 30,
          textAlign: "center",
        }}
      >
        © {new Date().getFullYear()}, Built by Ace
      </footer>
    </>
  )
}

export default Layout
